import { FormEvent, useRef, useState } from 'react'
import { Button } from '../button'
import FileUploader from '../file-uploader'
import { ChatHandler } from './chat.interface'
import TextArea from '@/app/components/ui/text-area'
import { AlertTriangle, Send } from 'lucide-react'
import { Alert, AlertTitle } from '@/app/components/ui/alert'
import UploadFilePreview from '@/app/components/ui/upload-file-preview'
import { ChatModeToggleGroup } from '@/app/components/mode-selection'

export interface Attachment {
  name?: string
  contentType?: string
  url: string
}

export default function ChatInput(
  props: Pick<
    ChatHandler,
    | 'isLoading'
    | 'input'
    | 'onFileUpload'
    | 'onFileError'
    | 'handleSubmit'
    | 'handleInputChange'
  > & {
    multiModal?: boolean
    isLimitExceeded: boolean
    chatModes: string[]
  },
) {
  const [attachments, setAttachments] = useState<Attachment[]>([])

  const formRef = useRef<HTMLFormElement>(null)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const ops = attachments ? { experimental_attachments: attachments } : {}
    props.handleSubmit(e, ops)
    setAttachments([])
  }

  const handleUploadFile = async (file: File) => {
    try {
      const attachment = await new Promise<Attachment>((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = () =>
          resolve({
            name: file.name,
            contentType: file.type,
            url: reader.result as string,
          })

        reader.onerror = () => reject(reader.error)

        reader.readAsDataURL(file)
      })

      setAttachments((prevAttachments) => [...prevAttachments, attachment])
    } catch (error) {
      props.onFileError?.(error.message)
    }
  }

  const handleRemoveFile = (attachment: Attachment) => {
    setAttachments((currentAttachments) =>
      currentAttachments.filter((a) => a.name !== attachment.name),
    )
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault()

      if (formRef.current) {
        // create a synthetic form event for onSubmit
        // @ts-ignore
        const syntheticEvent: FormEvent<HTMLFormElement> = new Event('submit', {
          bubbles: true,
          cancelable: true,
        })
        onSubmit(syntheticEvent)
      }
    }
  }

  return (
    <form
      ref={formRef}
      onSubmit={onSubmit}
      className="rounded-xl bg-white p-4 shadow-xl space-y-2"
    >
      {props.isLimitExceeded && (
        <Alert variant="default">
          <AlertTriangle className="w-4 h-4 text-red-500" />
          <AlertTitle>
            Usage quota exceeded. You can still chat with the free models.
          </AlertTitle>
        </Alert>
      )}
      {attachments.length != 0 && (
        <div className="flex gap-2">
          {attachments.map((attachment) => (
            <UploadFilePreview
              key={attachment.name}
              attachment={attachment}
              onRemove={() => handleRemoveFile(attachment)}
            />
          ))}
        </div>
      )}
      {props.chatModes.length > 1 && (
        <div className="flex items-center gap-1">
          <div className="text-xs">Chatbot mode:</div>
          <ChatModeToggleGroup />
        </div>
      )}
      <div className="flex w-full items-start justify-between gap-4 ">
        <TextArea
          autoFocus
          name="message"
          placeholder="Type a message"
          className="flex-1"
          value={props.input}
          onChange={props.handleInputChange}
          onKeyDown={onKeyDown}
        />
        {props.multiModal && (
          <FileUploader
            onFileUpload={handleUploadFile}
            onFileError={props.onFileError}
            config={{
              allowedExtensions: ['pdf', 'txt', 'docx'],
              fileSizeLimit: 1024 * 1024 * 5, // 5 MB
              disabled: false,
            }}
          />
        )}
        <div className="flex-col space-y-2">
          {/*{props.chatModes.length > 1 && (*/}
          {/*  <div className="space-y-1">*/}
          {/*    <div className="text-xs">Chatbot mode:</div>*/}
          {/*    <ChatModeToggleGroup />*/}
          {/*  </div>*/}
          {/*)}*/}

        <Button
          size="default"
          className="py-0 px-2 sm:h-10 sm:w-10 md:h-10 md:w-auto md:px-4 md:py-2"
          type="submit"
        >
          <span className="hidden md:inline">Send message</span>
          <Send className="md:ml-2" aria-hidden="true" />
        </Button>
        </div>
      </div>
    </form>
  )
}
