import { User2 } from 'lucide-react'
import Image from 'next/image'

export default function ChatAvatar({ role }: { role: string }) {
  const avatarClass =
    'flex h-8 w-8 md:h-10 md:w-10 shrink-0 select-none items-center justify-center rounded-md border bg-background shadow'
  if (role === 'user') {
    return (
      <div className={avatarClass}>
        <User2 className="h-4 w-4 md:h-6 md:w-6" />
      </div>
    )
  }

  const chatAvatarFile = process.env.NEXT_PUBLIC_AVATAR_NAME || 'beni_avatar.jpeg'

  return (
    <div className={avatarClass}>
      <Image
        className="rounded-md"
        src={`/${chatAvatarFile}`}
        alt="Logo"
        width={40}
        height={40}
        priority
      />
    </div>
  )
}
