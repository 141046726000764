'use client'

import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  SetStateAction,
  Dispatch,
} from 'react'
import { getUserChats, getUsage, Model, Chat, Usage } from '@/app/api'
import { generateId } from 'ai'

interface ChatContextType {
  usage: Usage
  refreshUsage: () => void
  chatModel: Model
  setChatModel: Dispatch<SetStateAction<Model>>
  chatId: string
  setChatId: Dispatch<SetStateAction<string>>
  disabled: boolean
  chats: Chat[]
  refreshChats: () => void
  chatMode: string | undefined
  setChatMode: Dispatch<SetStateAction<string | undefined>>
  chatModes: string[]
}

const ChatContext = createContext<ChatContextType | undefined>(undefined)

interface UsageProviderProps {
  children: ReactNode
  initialChatId?: string
  initialModel: Model
  initialUsage: Usage
  chatModes: string[]
}

export const ChatProvider = ({
  children,
  initialChatId,
  initialUsage,
  initialModel,
  chatModes,
}: UsageProviderProps) => {
  const [chatId, setChatId] = useState(initialChatId || generateId())
  const [usage, setUsage] = useState<Usage>(initialUsage)
  const [chats, setChats] = useState<Chat[]>([])
  const [chatModel, setChatModel] = useState<Model>(initialModel)
  const [chatMode, setChatMode] = useState<string | undefined>(
    chatModes.length > 0 ? chatModes[0] : undefined,
  )

  const refreshUsage = useCallback(() => {
    getUsage().then(setUsage)
  }, [])

  useEffect(() => {
    refreshUsage()
  }, [refreshUsage])

  const refreshChats = useCallback(() => {
    getUserChats().then(setChats)
  }, [])

  useEffect(() => {
    refreshChats()
  }, [refreshChats])

  const disabled = usage.isLimited

  return (
    <ChatContext.Provider
      value={{
        usage,
        refreshUsage,
        chatModel,
        setChatModel,
        disabled,
        chatId,
        setChatId,
        chats,
        refreshChats,
        chatMode,
        setChatMode,
        chatModes,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export const useChatContext = (): ChatContextType => {
  const context = useContext(ChatContext)
  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatProvider')
  }
  return context
}
