import {
  FileIcon,
  FileImageIcon,
  FileTextIcon,
  MusicIcon,
  VideoIcon,
  XCircleIcon,
} from 'lucide-react'
import { Attachment } from '@/app/components/ui/chat/chat-input'

interface UploadFilePreviewProps {
  attachment: Attachment
  onRemove?: () => void
}

export default function UploadFilePreview({
  attachment,
  onRemove,
}: UploadFilePreviewProps) {
  const { name, contentType } = attachment

  const getFileIcon = (type?: string) => {
    if (!type) return <FileIcon />
    if (type.startsWith('image/')) return <FileImageIcon />
    if (type.startsWith('video/')) return <VideoIcon />
    if (type.startsWith('audio/')) return <MusicIcon />
    if (type.startsWith('application/pdf')) return <FileIcon />
    return <FileTextIcon />
  }

  return (
    <div className="relative w-fit max-w-sm p-2 bg-gray-100 rounded-lg group">
      <div className="flex items-center gap-2">
        {getFileIcon(contentType)}
        {name}
      </div>
      {onRemove && (
        <button
          className="absolute -top-2 -right-2 w-6 h-6 bg-gray-500 text-white rounded-full hidden group-hover:block"
          onClick={onRemove}
        >
          <XCircleIcon className="w-full h-full" />
        </button>
      )}
    </div>
  )
}
