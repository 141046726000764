import React, { TextareaHTMLAttributes, useEffect, useRef, forwardRef } from "react";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  handleInputChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const TextArea: React.FC<TextAreaProps> = forwardRef(({ className, handleInputChange, ...props }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const adjustHeight = () => {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    adjustHeight();
    textarea.addEventListener("input", adjustHeight);

    return () => textarea.removeEventListener("input", adjustHeight);
  }, [props.value]);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    if (handleInputChange) {
      handleInputChange(e);
    }
  };

  return (
      <textarea
          ref={textareaRef}
          className={`resize-none overflow-y-auto min-h-[10dvh] max-h-[30dvh] md:max-h-40 flex w-full rounded-md border border-input bg-background px-4 py-2 text-base md:text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50 ${className || ""}`}
          onChange={handleChange}
          {...props}
      />
  );
});

export default TextArea;
