import { FC, memo } from "react";
import ReactMarkdown, { Options } from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeSanitize from "rehype-sanitize";
import rehypeExternalLinks from "rehype-external-links";
import { defaultSchema } from "rehype-sanitize";
import 'katex/dist/katex.min.css';

const markdownStyles = {
  ul: {
    listStyleType: 'disc',
    marginLeft: '20px',
  },
  ol: {
    listStyleType: 'decimal',
    marginLeft: '20px',
  },
  li: {
    marginBottom: '5px',
  },
}

const MemoizedReactMarkdown: FC<Options> = memo(
  ReactMarkdown,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children &&
    prevProps.className === nextProps.className,
);

export default function Markdown({ content }: { content: string }) {
  return (
    <MemoizedReactMarkdown
      className="prose dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 break-words"
      remarkPlugins={[remarkMath]}
      rehypePlugins={[
        [
          rehypeSanitize,
          {
            ...defaultSchema,
            tagNames: [
              ...(defaultSchema.tagNames || []),
              "h1",
              "h2",
              "h3",
              "h4",
              "h5",
              "h6",
            ],
            attributes: {
              ...defaultSchema.attributes,
              div: [
                ...(defaultSchema?.attributes?.div || []),
                ["className", "math", "math-display"],
              ],
              span: [
                ...(defaultSchema?.attributes?.span || []),
                ["className", "math", "math-inline"],
              ],
              a: [
                ...(defaultSchema?.attributes?.a || []),
                ["className", "href", "target", "rel"],
              ],
            },
          },
        ],
        [rehypeKatex, { output: "mathml" }],
        [
          rehypeExternalLinks,
          {
            target: "_blank",
            rel: ["noopener", "noreferrer", "nofollow"],
          },
        ],
      ]}
      components={{
        p({ children }) {
          return <p className="mb-2 last:mb-0">{children}</p>;
        },
        a({ node, ...props }) {
          return (
            <a
              style={{ color: "blue", textDecoration: "underline" }}
              {...props}
            />
          );
        },
        ul({ children }) {
          return <ul style={markdownStyles.ul}>{children}</ul>;
        },
        ol({ children }) {
          return <ol style={markdownStyles.ol}>{children}</ol>;
        },
        li({ children }) {
          return <li style={markdownStyles.li}>{children}</li>;
        },
      }}
    >
      {content}
    </MemoizedReactMarkdown>
  );
}
