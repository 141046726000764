import React, { useState, useRef } from "react";
import { Button } from "@/app/components/ui/button";

interface CommentPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (comment: string) => void;
}

export default function CommentPopup({
  isOpen,
  onClose,
  onSubmit,
}: CommentPopupProps) {
  const [comment, setComment] = useState("");
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      isOpen &&
      popupRef.current &&
      !popupRef.current.contains(event.target as Node)
    ) {
      onClose();
    }
  };
  // close popup on click outside
  document.addEventListener("mousedown", handleClickOutside);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex justify-center items-center">
      <div
        className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full"
        ref={popupRef}
      >
        <textarea
          className="w-full p-2 border border-gray-300 rounded resize-none"
          rows={4}
          value={comment}
          placeholder="Comment on the tutorbot response..."
          onChange={(e) => setComment(e.target.value)}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              onSubmit(comment);
              setComment("");
              onClose();
            }
          }}
        ></textarea>
        <div className="flex justify-end space-x-2 mt-4">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit(comment);
              setComment("");
              onClose();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
